<template lang="pug">
#equipment
    b-container(style="max-width: 800px;")
        .text-center.mt-4
            h4.mt-blue Equipment to fit your Needs and Budget
            b-img(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Medten_Equipment.36bd9530.jpeg" fluid)

        p Medten carries mostly minor medical equipment. We can however source any medical equipment to meet your needs as a healthcare provider. Please take a look at our category list of Equipment and if you don't see what you are looking for submit your request 
            a(href="/sourcing/") on this form 
            | or email us at info@medten.com
        .quality-block
            .mt-blue
                h3.light-font Categories of Equipment we can provide:
                
        ul.quality-list
            li Ambulatory Infusion Pumps
            li Fetal Monitoring
            li Infusion Pumps
            li Infusion Pump Pc Units
            li Large Volume Infusion Pumps
            li Modules
            li Patient Monitors
            li Pca Infusion Pumps
            li Physiological Patient Monitoring Systems
            li Syringe Infusion Pumps
            li Telemetry Transmitters 
            li Transport Monitoring Systems
            li Vital Signs Monitoring Systems
    MissionPromiseStatement
    Footer
</template>

<script>
import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
export default {
    components: {
        MissionPromiseStatement,
        Footer
    },
}
</script>
<style scoped>
    .mt-blue {
        color: #68C3C5;
    }
    .quality-list {
        font-weight: 500;
        margin-bottom: 50px;
    }
</style>