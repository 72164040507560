<template lang="pug">
b-container#landing.p-0(fluid)
  b-container(fluid :style="getStyle()")
    b-carousel#carousel-fade.carousel-image.banner-carousel(fade indicators v-if="currentLocale === 'zh'" :interval="10000")
      b-carousel-slide(img-src='../assets/images/Welcome/banner_china.jpg')
    b-carousel#carousel-fade.carousel-image.banner-carousel(fade indicators v-else :interval="10000")  
      b-carousel-slide(img-src='../assets/images/Welcome/Touch_Screens_Banner_for_OPC.jpg')
    b-container.pt-4.product-search
      b-row.justify-content-center.align-items-center.bg-white.shadow-manual.p-4.m-2.rounded
        b-col.p-2(cols="12" md="auto")
          span.h5.text-primary(:style="primaryColor") {{ $t('landing.label.search_by_device') }}
        b-col.p-2(cols="12" md="auto")
          typeahead( v-model="brand_search" 
            class="search-input"
            :data="brands" 
            :showOnFocus="true"
            :serializer="siteSwitch"
            :maxMatches="1000"
            :placeholder="$t('landing.label.choose_manufacturer')" 
            @hit="brandSelected"
            @keyup="brandInput")
        b-col.p-2(cols="12" md="auto")
          typeahead( v-model="model_search" 
            class="search-input"
            :data="searchModels"
            :showOnFocus="true"
            :serializer="m => m.model"
            :maxMatches="4800"
            :placeholder="$t('landing.label.choose_model')" 
            @hit="modelSelected"
            @keyup="modelInput")
        b-col.p-2(cols="12" md="auto")
          b-button.text-light.pl-4.pr-4.btn-find(variant="primary" @click="searchByDevice" :style="primaryBgColor")
            span.color1 {{ $t('landing.label.find') }}
    b-container.pt-4.pb-4
      p.text-center.h3.mb-4 {{$t('landing.label.popular_brands')}} 
      b-row.justify-content-center.align-items-center.brand-item-group
        b-col.brand-item(cols="12" sm="auto" v-for="brand, idx in filter_brands" :key="idx" @click="searchByBrand(brand)")
          //- b-img(fluid :src="getBrandImg(brand)" width="100")
          .brand-name(:style="primaryColor") 
            TranslateValue(:sourceObj="brand")
      .show-all-brand.text-center.mt-3
        b-button.text-light.shadow-manual(variant="primary" @click="onBrandModeBtn" :style="primaryBgColor").p-2.pl-3.pr-3.full-width
          span {{ (this.display_brand_index != this.total_no_brands) ? $t('landing.label.more_brands') : $t('landing.label.show_less')}}
    
  b-container.mt-4.product-features-block(fluid v-if="featured_products && featured_products.length")
    p.text-center.h3.mb-4 {{$t('landing.label.featured_products')}}
    VueSlickCarousel.desktop-show(v-bind="settings")
      template(#prevArrow="arrowOption")
        b-img.custom-arrow(src="@/assets/images/btn-left.svg")
      GlobalCard(v-for="(item, key) in featured_products" :key="key" :item="item" page="fp")
      template(#nextArrow="arrowOption")
        b-img.custom-arrow(src="@/assets/images/btn-right.svg")
    b-row.mobile-show
      b-col.p-0.pb-3(cols="6" md="3" v-for="pf in filter_featured_products" :key="'mob-'+pf.id")
        GlobalCard(:item="pf" page="fp")
    .load-more-icon.justify-content-center.mobile-show(v-if="display_pf_index < featured_products.length") 
      b-img.custom-arrow(src="@/assets/images/btn-load.svg" @click="loadmorePf()")
  
  b-container.mt-3.product-features-block(fluid v-if="new_arrivals && new_arrivals.length")
    p.text-center.h3.mb-4.border-block-top.pt-4 {{$t('landing.label.new_arrivals')}}
    VueSlickCarousel.desktop-show(v-bind="settings")
      template(#prevArrow="arrowOption")
        b-img.custom-arrow(src="@/assets/images/btn-left.svg")
      GlobalCard(v-for="(item, key) in new_arrivals" :key="'pi-'+key" :item="item" page="na")
      template(#nextArrow="arrowOption")
        b-img.custom-arrow(src="@/assets/images/btn-right.svg")
    b-row.mobile-show
      b-col.p-0.pb-3(cols="6" md="3" v-for="pi in filter_new_arrivals" :key="'mob-'+pi.id")
        GlobalCard(:item="pi" page="na")
    .load-more-icon.justify-content-center.mobile-show(v-if="display_na_index < new_arrivals.length") 
      b-img.custom-arrow(src="@/assets/images/btn-load.svg" @click="loadmoreNa()")
  
  b-container.mt-3.product-features-block(fluid v-if="featured_categories && featured_categories.length")
    p.text-center.h3.mb-4.border-block-top.pt-4 {{$t('landing.label.featured_categories')}}
    VueSlickCarousel.desktop-show(v-bind="settings")
      template(#prevArrow="arrowOption")
        b-img.custom-arrow(src="@/assets/images/btn-left.svg")
      GlobalCard(v-for="(item, key) in featured_categories" :key="key" :item="item" page="fc")
      template(#nextArrow="arrowOption")
        b-img.custom-arrow(src="@/assets/images/btn-right.svg")
    b-row.mobile-show
      b-col.p-0.pb-3(cols="6" md="3" v-for="fc in filter_featured_categories" :key="'mob-'+fc.id")
        GlobalCard(:item="fc" page="fc")
    .load-more-icon.justify-content-center.mobile-show(v-if="display_fc_index < featured_categories.length") 
      b-img.custom-arrow(src="@/assets/images/btn-load.svg" @click="loadmoreFc()")

  b-container.mt-3.product-features-block(fluid v-if="popular_items && popular_items.length")
    p.text-center.h3.mb-4.border-block-top.pt-4 {{$t('landing.label.popular_items')}}
    VueSlickCarousel.desktop-show(v-bind="settings")
      template(#prevArrow="arrowOption")
        b-img.custom-arrow(src="@/assets/images/btn-left.svg")
      GlobalCard(v-for="(item, key) in popular_items" :key="'pi-'+key" :item="item" page="pi")
      template(#nextArrow="arrowOption")
        b-img.custom-arrow(src="@/assets/images/btn-right.svg")
    b-row.mobile-show
      b-col.p-0.pb-3(cols="6" md="3" v-for="pi in filter_popular_items" :key="'mob-'+pi.id")
        GlobalCard(:item="pi" page="pi")
    .load-more-icon.justify-content-center.mobile-show(v-if="display_pi_index < popular_items.length") 
      b-img.custom-arrow(src="@/assets/images/btn-load.svg" @click="loadmorePi()")
  
  MissionPromiseStatement
  
  Footer
</template>

<script>
import utils from "@/mixins/utils";
import { mapActions, mapState, mapGetters } from 'pinia';
import { useApiUtilStore} from '../stores/apiutil-st';
import { useDbStore } from '../stores/db-st';
import { useBaseStore } from '../stores/base-st';
import { useProductSearchStore} from '../stores/product-search-st';
import debounce from 'lodash.debounce';
import GlobalCard from '@/components/GlobalCard.vue';
import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
import TranslateValue from '@/components/TranslateValue.vue';
import VueSlickCarousel from 'vue-slick-carousel';
const img = require('@/assets/images/bg-brand-device-search.svg');

export default {
  name: 'Landing',
  mixins: [utils],
  components: {
    GlobalCard,
    VueSlickCarousel,
    Footer,
    MissionPromiseStatement,
    TranslateValue
  },
  data(){
    return {
      models_for_brand: [],
      brand_search: '',
      selected_brand_id: null,
      model_search: '', // (models)
      selected_model: null,
      
      total_no_brands: 0,
      display_brand_index: 12,

      display_pf_index: 4,
      display_fc_index: 4,
      display_pi_index: 4,
      display_na_index: 4,      

      brand_list_medten:[
        {
          id: 17,
          name: 'Criticare',
          img:'brand1.svg'
        },
        {
          id: 1,
          name: 'GE Healthcare',
          img:'brand2.svg'
        },
        {
          id: 3,
          name: 'Philips',
          img:'brand3.svg'
        },
        {
          id: 13,
          name: 'Nellcor',
          img:'brand4.svg'
        },
        {
          id: 146,
          name: 'Hospira / Abbott / ICU Medical',
          //- name: 'Hospira',
          img:'brand5.svg'
        },
        {
          id: 62,
          name: 'Baxter',
          img:'brand6.svg'
        },
        {
          id: 288,
          name: 'Welch Allyn',
          img:'brand7.svg'
        },
        {
          id: 2,
          name: 'Mindray',
          img:'brand8.svg'
        },
        {
          id: 14,
          name: 'Masimo',
          img:'brand9.svg'
        },
        //- {
        //-   id: 90,
        //-   name: 'Alaris / Carefusion / BD',
        //-   //- name: 'Carefusion',
        //-   img:'brand10.svg'
        //- },
        {
          id: 7,
          name: 'Draeger',
          img:'brand11.png'
        },
        {
          id: 10,
          name: 'Spacelabs',
          img:'brand12.png'
        },
        {
          id: 20,
          //- id: 302,
          name: 'Medfusion / Smiths Medical / ICU Medical',
          //- name: 'Medfusion',
          img:'brand13.png'
        },
        {
          id: 31,
          name: 'Alaris / Carefusion / BD',
          //- name: 'Alaris',
          img:'brand14.svg'
        },
        {
          id: 22,
          name: 'Datex Ohmeda',
          img:'brand15.jpeg'
        },
        {
          id: 157,
          name: 'Invivo',
          img:'brand16.png'
        },
        {
          id: 237,
          name: 'Schiller',
          img:'brand17.png'
        },

        {
          id: 294,
          name: 'Zoll',
          img:'brand18.png'
        },
        {
          id: 35,
          name: 'Siemens',
          img:'brand19.png'
        },
        {
          id: 9,
          name: 'Nihon Kohden',
          img:'brand20.png'
        }
      ],
      brand_list:[
        {
          id: 298,
          name_en: 'AAMI',
          img:''
        },
        {
          id: 59,
          name_en: 'B. Braun',
          img:''
        },
        {
          id: 36,
          name_en: 'Burdick',
          img:''
        },
        {
          id: 104,
          name: 'Covidien',
          img:''
        },
        {
          id: 26,
          name_en: 'Critikon',
          img:'brand5.svg'
        },
        {
          id: 27,
          name_en: 'Critikon / Dinamap',
          img:'brand6.svg'
        },
        {
          id: 22,
          name_en: 'Datex Ohmeda',
          img:'brand7.svg'
        },
        {
          id: 148,
          name_en: 'Huntleigh',
          img:''
        },
        {
          id: 157,
          name_en: 'Invivo',
          img:''
        },
        {
          id: 105,
          name_en: 'Kendall',
          img:'brand10.svg'
        },
        {
          id: 14,
          name_en: 'Masimo',
          img:''
        },
        {
          id: 15,
          name_en: 'Medtronic',
          img:''
        },
        {
          id: 195,
          name_en: 'Mortara',
          img:''
        },
        {
          id: 13,
          name_en: 'Nellcor',
          img:''
        },
        {
          id: 204,
          name_en: 'Nonin',
          img:''
        },
        {
          id: 208,
          name_en: 'Novametrix',
          img:''
        },
        {
          id: 257,
          name_en: 'Physio-Control',
          img:''
        },

        {
          id: 106,
          name_en: 'Puritan Bennett',
          img:''
        },
        {
          id: 16,
          name_en: 'YSI',
          img:''
        },
        {
          id: 294,
          name_en: 'Zoll',
          img:'brand20.png'
        }
      ],
      settings:{
        dots: false,
        arrows: true, 
        infinite: false,
        nextArrow: '<button class="carousel-arrow next inverted"></button>',
        prevArrow: '<button class="carousel-arrow prev inverted"></button>',
        //- centerMode: true,
        //- centerPadding: '10px',
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
          {
            breakpoint: 1350,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: false,
              dots: false,
              arrows: true,
              nextArrow: '<button class="carousel-arrow next inverted"></button>',
              prevArrow: '<button class="carousel-arrow prev inverted"></button>',
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: false,
              dots: false,
              arrows: true,
              nextArrow: '<button class="carousel-arrow next inverted"></button>',
              prevArrow: '<button class="carousel-arrow prev inverted"></button>',
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: false,
              dots: false,
              arrows: true,
            }
          },
          {
            breakpoint: 678,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: false,
              dots: false,
              arrows: true,
            }
          }
        ]
      }
    };
  },
  mounted(){
  this.clearSearch()
  },
  computed:{
    ...mapState( useDbStore, ['brands','models', 'customer', 'categories', 'popular_brands', 'popular_branded_brands', 'featured_categories'] ),
    ...mapState( useProductSearchStore, ['new_arrivals', 'popular_items', 'featured_products', 'product_search_results','product_search_total']),
    ...mapGetters(useDbStore, ['isMedtenCustomer', 'primaryColor', 'secondaryColor', 'primaryBgColor', 'secondaryBgColor']),
    ...mapState( useApiUtilStore, ['busy']),
    searchModels(){
      const result = this.models.filter(model => model.model).sort((a, b) => {
        let modelA = a.model.trim();
        let modelB = b.model.trim();
        return modelA.localeCompare(modelB);
      });
      return result
    },
    currentLocale(){
      return this.$i18n.locale
    },
    model_options(){
      if(!this.models) return [];
      let unique = [];
      this.models.forEach(m=>{
        if(m.model){
          let i = unique.find(opt=>opt.value==m.model);
          if(i < 0) unique.push({value: m.model, text: m.model})
        }
      });
      return unique.sort();
    },

    filter_brands() {
      /** for diffrent brands for medten and branding **/
      return this.sortRanks(this.isMedtenCustomer
        ? this.popular_brands
        : this.popular_branded_brands).slice(0, this.display_brand_index)
    },

    filter_featured_products(){
      if(this.featured_products && this.featured_products.length > this.display_pf_index){
        return this.featured_products.slice(0, this.display_pf_index);
      }
      return this.featured_products;
    },

    filter_featured_categories(){
      if(this.featured_categories && this.featured_categories.length > this.display_fc_index){
        return this.featured_categories.slice(0, this.display_fc_index);
      }
      return this.featured_categories;
    },

    filter_popular_items(){
      if(this.popular_items && this.popular_items.length > this.display_pi_index){
        return this.popular_items.slice(0, this.display_pi_index);
      }
      return this.popular_items;
    },

    filter_new_arrivals(){
      if(this.new_arrivals && this.new_arrivals.length > this.display_na_index){
        return this.new_arrivals.slice(0, this.display_na_index);
      }
      return this.new_arrivals;
    }
  },
  // clearSearch(){
  //    VueOfflineStorage.set('refine_filter_options', null)
  //    VueOfflineStorage.set('refine_models', null)
  //    VueOfflineStorage.set('refine_brands', null)
  //    VueOfflineStorage.set('refine_categories', null)
  //    VueOfflineStorage.set('refine_lifecycles', null)
  //   },
  // mounted(){
  //   clearSearch()
  // },
 
  async created(){
    //- useProductSearchStore().$reset();
    this.resetBrandDisylay();
    await this.hydrateCustomer();
    await this.loadCategories();
    Promise.allSettled([
      this.loadPopularBrands(),
      this.loadPopularBrandedBrands(),
      this.loadNewArrivals(),
      this.loadPopularProduct(),
      this.loadFeaturedProduct(),
      this.loadFeaturedCategories(),
    ])
    console.log(this.new_arrivals, this.popular_items, this.featured_products);
    //- console.log(this.brands,this.models, this.featured_categories);
    //- let todayDate = new Date();
    //- todayDate.setDate(todayDate.getDate() - 60);
    //- const yyyy = todayDate.getFullYear();
    //- let mm = todayDate.getMonth() + 1; // Months start at 0!
    //- if(mm<10) mm = "0"+mm;
    //- let dd = todayDate.getDate();
    //- if(dd<10) dd = "0"+dd;
    //- let dateStr = yyyy+"-"+mm+"-"+dd;
  },
  beforeDestroy(){
    console.log(this.new_arrivals, this.popular_items, this.featured_products);
  },

  methods:{
    ...mapActions( useBaseStore, ['flashMessage']),
    ...mapActions( useDbStore, ['hydrateCustomer','loadBrands', 'loadPopularBrands', 'loadPopularBrandedBrands', 'loadCategories','loadFilterOptions','loadModels', 'loadFeaturedCategories']),
    ...mapActions( useProductSearchStore, ['searchProductsForDevice', 'loadPopularProduct', 'loadFeaturedProduct', 'loadNewArrivals']),
    brandSelected(b){ this.selected_brand_id = b.id; },
    brandInput: function(){
      return debounce(() => { 
        if(!this.brand_search) this.selected_brand_id = null;
      }, 500);
    },
    siteSwitch(b){
       return this.$i18n.locale === 'zh' ? b.name_zh : b.name_en
      
    },
    sortRanks(data) {
      const recordsWithNoRank = data.filter(item => !item.rank_us)
      const recordsWithRank = data.filter(item => item.rank_us)
      return [
        ...recordsWithRank.sort((a, b) => (a.rank_us - b.rank_us)),
        ...recordsWithNoRank.sort((a, b) => a.name_en.localeCompare(b.name_en))
      ]
    },
    modelSelected(m){ this.model_search = m.model; },
    modelInput: function(){
      return debounce(() => { 
        if(!this.model_search) this.model_search = null;
      }, 500);
    },
    getStyle(){
      // console.log('isMedtenCustomer', this.isMedtenCustomer);

      if(this.isMedtenCustomer){
        return `background-image: url("${img}");`;
      }else{
        return `background: #ededed`;
      }
    },
    getBrandImg(brand){
      return require(`@/assets/images/brands/${brand.img}`)
    },
    isMobile(){
      if(window.innerWidth <= 767){
        return true;
      }else{
        return false;
      }
    },
    resetBrandDisylay(){
      this.total_no_brands = this.brand_list.length;
      if(this.isMobile()){
        this.display_brand_index = 6;
      }else{
        this.display_brand_index = 10;
      }
    },

    onBrandModeBtn(){
      let tempIndex = this.display_brand_index;
      if(this.display_brand_index != this.total_no_brands){
        //- tempIndex = this.display_brand_index + 5;
        //- if(this.isMobile()) tempIndex = this.display_brand_index + 3;
        //- if(tempIndex > this.total_no_brands){
        this.display_brand_index = this.total_no_brands;
        //- }else{
        //-   this.display_brand_index = tempIndex
        //- }
      }else{
        this.resetBrandDisylay();
      }
    },

    loadmoreNa(){
      if(this.new_arrivals && this.new_arrivals.length > this.display_na_index){
        this.display_na_index = this.new_arrivals.length;
      }
    },

    loadmorePi(){
      if(this.popular_items && this.popular_items.length > this.display_pi_index){
        this.display_pi_index = this.popular_items.length;
      }
    },

    loadmorePf(){
      if(this.featured_products && this.featured_products.length > this.display_pf_index){
        this.display_pf_index = this.featured_products.length;
      }
    },
    loadmoreFc(){
      if(this.featured_categories && this.featured_categories.length > this.display_fc_index){
        this.display_fc_index = this.featured_categories.length;
      }
    },
    searchByBrand(brand){
      this.clearSearch()
      console.log(brand);
      this.selected_brand_id = brand.id;
      this.model_search = "";
      this.searchByDevice();
    },
    async searchByDevice(){
      let criteria = {};
      if(this.selected_brand_id){ 
        criteria.brand_id = this.selected_brand_id;
      }
      if(this.model_search){
        criteria.model = this.model_search;
      } 
      await this.searchProductsForDevice(criteria);
      if(this.product_search_total === 1 && this.product_search_results[0].id){
        //- if(this.$route.name !== 'Product') this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        if(this.$route.name !== 'Product'){ 
          this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        }else if(this.$route.params && this.$route.params['product_id'] && (this.$route.params['product_id'] != this.product_search_results[0].id)){
          this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        }
      } else if (this.product_search_total > 1) {
        if(this.$route.name !== 'Search') this.$router.push({ name: 'Search' });
      } else {
        let msg = ""
        if(this.selected_brand_id){
          let brand = this.brands.find(item => item.id == this.selected_brand_id);
          if(brand) msg = brand.name_en
        }
        if(this.model_search){
          msg = msg ? (msg +' and '+ this.model_search) : this.model_search;
        }
        msg = '"'+ msg +'" products coming soon';
        this.flashMessage(msg, 3);
      }
    },
    
    // ...mapActions(useDbStore, ['loadBrands','loadModels']),
    // async loadModelsForBrand(){
    //   let api = useApiUtilStore();
    //   let r = await api.callApi({method: 'GET', url: `/equipment`, parms: {brand_id: this.selected_brand_id} });
    //   if(r.success) this.models_for_brand = r.data.equipment_views;
    // }
  }
}
</script>

<style lang="scss" scoped>
  #landing{
    .b-overlay{
      position: fixed !important;
    }
    .shadow-manual {
      box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
    }
    .brand-item-group{
      .brand-item{
        margin: 0.2rem !important;
        text-align: center;
        padding: 0.5rem;
        img{
          cursor: pointer;
        }
        /*
        .brand-name{
          cursor: pointer;
          font-size: 1.25rem;
          font-weight: 600;
          min-width: 180px;
          color: #36c6cc;
          background: white;
          border-radius: 5px;
          box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
          padding: 0.6rem 0.6rem;
        }*/
        .brand-name{
          cursor: pointer;
          font-size: 1.2rem;
          font-weight: 600;
          min-width: 200px;
          min-height: 70px;
          max-width: 200px;
          color: #36c6cc;
          background: white;
          border-radius: 5px;
          box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
          padding: 0.4rem 0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.2;
        }
      }
    }
    .product-features-block{
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .border-block-top{
      border-top: 1px solid #b3b0b0;
    }
    
    .carousel-image {
      width:1200px;
     
      margin: auto;
      width: 100%;
    }

    @media (max-width:450px){
      .banner-carousel{
        display: none;
      }
    }

    @media (max-width: 767px){
      .product-search{
        .btn-find{
          width: 100%
        }
        .input{
          min-width: 200px;
        }
      }
      .product-features-block{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      .brand-item-group{
        .brand-item{
          text-align: center;
          margin: 0 !important;
          img{
            width: 130px;
          }
          .brand-name{
            font-size: 1.2rem;
            font-weight: 600;
            min-width: 70px;

            max-width: 100%;
            padding: 0.5rem;
            min-height: 70px;
          }
        }
      }
    }
    @media (min-width: 768px){
      .product-search{
        max-width: fit-content !important;
      }
    }
    @media (min-width: 1024px){
      .product-search{
        .search-input{
          min-width: 300px;
        }
      }
    }
  }
</style>
